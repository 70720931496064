import React from "react";
import { useNavigate } from "react-router-dom";
import * as Api from '../api/Api';
import SettingsIcon from '@mui/icons-material/Settings';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { Compare } from "@mui/icons-material";
import { useTranslation } from 'react-i18next'; // Import the hook

const MainView = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation(); // Destructure the t function

    return (
        <div className="px-4 xl:px-40 xl:max-w-[1200px] xl:mx-auto flex flex-col h-screen">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 my-auto">
                <div
                    onClick={e => navigate(Api.SETS_TESTING)}
                    className="p-9 rounded-lg cursor-pointer hover:bg-white bg-white/50 transition-all"
                >
                    <div className="flex flex-col gap-8 text-left">
                        <div className="relative w-16 h-16 flex flex-col">
                            <div className="bg-[#e2f6d1] z-0 absolute filter w-16 h-16 overflow-hidden rounded-lg"></div>

                            <CompareArrowsIcon style={{ fontSize: '32px', color: 'green' }}
                                className="m-auto z-20"
                            />
                        </div>
                        <div className="flex flex-col gap-3 mt-16">

                            <span className="text-2xl font-medium">{t('setsTesting.title')}</span>
                            <p className="opacity-80">{t('setsTesting.description')}</p>

                        </div>
                    </div>
                </div>
                <div
                    onClick={e => navigate(Api.TESTS_SUMMARY)}
                    className="p-9 rounded-lg cursor-pointer hover:bg-white transition-all bg-white/50"
                >
                    <div className="flex flex-col gap-8 text-left">
                        <div className="relative w-16 h-16 flex flex-col">
                            <div className="bg-[#e2f6d1] z-0 absolute filter w-16 h-16 overflow-hidden rounded-lg"></div>

                            <Compare style={{ fontSize: '32px', color: 'green' }}
                                className="m-auto z-20"
                            />
                        </div>
                        <div className="flex flex-col gap-3 mt-16">

                            <span className="text-2xl font-medium">{t('testsSummary.title')}</span>
                            <p className="opacity-80">{t('testsSummary.description')}</p>

                        </div>
                    </div>
                </div>
                <div
                   onClick={e => navigate(Api.SETS_MANAGEMENT)}
                    className="aspect-square p-9 rounded-lg cursor-pointer transition-all hover:bg-white  bg-white/50"
                >
                    <div className="flex flex-col gap-8 text-left">
                        <div className="relative w-16 h-16 flex flex-col">
                            <div className="bg-[#e2f6d1] z-0 absolute filter w-16 h-16 overflow-hidden rounded-lg"></div>
                            <SettingsIcon style={{ fontSize: '32px', color: 'green' }}
                                className="m-auto z-20"
                            />
                        </div>
                        <div className="flex flex-col gap-3 mt-16">

                            <span className="text-2xl font-medium">{t('setsManagement.title')}</span>
                            <p className=" opacity-80">{t('setsManagement.description')}</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainView;
