import React, { useLayoutEffect, useState } from "react";
import './App.scss';
import "bootstrap/dist/css/bootstrap.css";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import keycloak from "./api/Keycloak";
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Box, ThemeProvider } from "@mui/material";
import ConfigToolTheme from "./components/Theme";
import { ProjectProvider } from "./context/project-context";
import MainView from "./Pages/MainView";
import SetsTesting from "./Pages/sets-testing/SetsTesting";
import SetsManagement from "./Pages/sets-management/SetsManagement";
import { HashRouter, Route, Routes } from "react-router-dom";
import * as Api from './api/Api';
import TestsSummary from "./Pages/tests-summary/TestsSummary";

const App = () => {
  const [, setWindowWidth] = useState(0);
  const [, setWindowHeight] = useState(0);
  const [, setIsAuthenticated] = useState(false);
  const [title, setTitle] = useState();


  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", resizeWindow);
    resizeWindow();
    return () => window.removeEventListener("resize", resizeWindow);
    //eslint-disable-next-line
  }, [keycloak]);

  const handleOnEvent = async (event, error) => {
    if (event === 'onAuthSuccess') {
      if (keycloak.authenticated) {
        setIsAuthenticated(true);
      }
    }
  };


  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false
      }}
      onEvent={(event, error) => handleOnEvent(event, error)}
    >
      {keycloak.token ? (
        <ThemeProvider theme={ConfigToolTheme}>
          <Box
            sx={{
              minWidth: "100%",
              height: "100vh",
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column'
            }}
          >
            <ProjectProvider>
              <HashRouter>
                <Header title={title} />
                <Routes>
                  <Route exact path="/" element={<MainView />} />
                  <Route exact path={Api.SETS_TESTING} element={<SetsTesting setTitle={setTitle} />} />
                  <Route exact path={Api.SETS_MANAGEMENT} element={<SetsManagement setTitle={setTitle} />} />
                  <Route path={Api.TESTS_SUMMARY} element={<TestsSummary />} />
                </Routes>
                <Footer />
              </HashRouter>
            </ProjectProvider>
          </Box>
        </ThemeProvider>
      ) : null}
    </ReactKeycloakProvider>
  );
}

export default App;