import React, { useContext, useEffect, useState } from 'react';
import ProjectsMenu from '../Menu/ProjectsMenu';
import ProjectContext from '../../context/project-context';
import keycloak from "../../api/Keycloak";
import { Compare, ExitToApp, Phone, Mail } from "@mui/icons-material";
import * as Api from '../../api/Api';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Settings from '@mui/icons-material/Settings';

const ProjectSelect = () => {
    const [projectsAnchorEl, setProjectsAnchorEl] = useState(null);
    const [projectsMenuOpen, setProjectsMenuOpen] = useState(false);
    const [userProfile, setUserProfile] = useState({ firstName: "", lastName: "", email: "" });
    const [menuOpen, setMenuOpen] = useState(false);
    const { projects, selectedProject, setSelectedProject } = useContext(ProjectContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    useEffect(() => {
        keycloak.loadUserProfile().then((profile) => {
            setUserProfile({
                firstName: profile.firstName || "",
                lastName: profile.lastName || "",
                email: profile.email || "",
            });
        });
    }, []);

    const handleProjectsMenuClick = (event) => {
        setProjectsMenuOpen(true);
        setProjectsAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        keycloak.logout(); // Trigger Keycloak logout
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setSelectedLanguage(lng);
        setMenuOpen(false);
    };

    return (
        <div className="flex gap-4 items-center ml-auto text-white relative">
            <div
                className='flex gap-1.5 cursor-pointer'
                onClick={handleProjectsMenuClick}>
                {selectedProject ? selectedProject.name : ""}
                <KeyboardArrowDownIcon />
            </div>

            <button
                className='flex gap-1.5 items-center'
                onClick={() => navigate(Api.SETS_TESTING)}>
                <SwapHorizIcon style={{ fontSize: '19px' }} />
                <span className="text-sm">{t('setsTesting.title')}</span>
            </button>

            <button
                className='flex gap-1.5 items-center'
                onClick={() => navigate(Api.TESTS_SUMMARY)}>
                <Compare style={{ fontSize: '19px' }} />
                <span className="text-sm">{t('testsSummary.title')}</span>
            </button>

            <button
                className='flex gap-1.5 items-center'
                onClick={() => navigate(Api.SETS_MANAGEMENT)}>
                <Settings style={{ fontSize: '19px' }} />
                <span className="text-sm">{t('setsManagement.title')}</span>
            </button>

            <button onClick={() => setMenuOpen(!menuOpen)} className="relative bg-white text-black rounded-full w-9 h-9 flex items-center justify-center">
                <span className="text-xs font-medium">
                    {userProfile.firstName[0]}{userProfile.lastName[0]}
                </span>
            </button>

            {menuOpen && (
                <div className="absolute text-black -right-4 top-9 mt-2 w-[22rem] bg-white shadow-lg rounded-lg py-4 px-4 z-10 menu-container">
                    <div className="flex gap-2 border-b pb-4">
                        <div className="w-16 h-16 text-center rounded-full border-[1.5px] border-[#3D9123] flex">
                            <div className="flex m-auto ">
                                <span className="text-3xl text-[#3D9123]">
                                    {userProfile.firstName[0]}
                                </span>
                                <span className="text-3xl text-[#3D9123]">
                                    {userProfile.lastName[0]}
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col pr-12">
                            <div className="flex gap-0.5 items-start">
                                <span className="font-medium mr-1">{userProfile.firstName}</span>
                                <span className="font-medium">{userProfile.lastName}</span>
                            </div>
                            <span className="text-sm opacity-80">{userProfile.email}</span>
                        </div>
                    </div>
                    <div className="flex w-full justify-between items-center mt-4">
                        <div className="flex text-sm gap-2 menu-item-c">
                            <div
                                className={`cursor-pointer px-2 py-1 rounded ${selectedLanguage === "cs" ? "bg-[#51c32e37]" : "text-black"}`}
                                onClick={() => changeLanguage('cs')}
                            >
                                CZ
                            </div>
                            <div
                                className={`cursor-pointer px-2 py-1 rounded ${selectedLanguage === "en" ? "bg-[#51c32e37]" : "text-black"}`}
                                onClick={() => changeLanguage('en')}
                            >
                                EN
                            </div>
                        </div>
                        <button className="text-sm flex gap-2 items-center" onClick={handleLogout}>
                            <ExitToApp className='opacity-80' width={18} height={18} />
                            <span>Logout</span>
                        </button>
                    </div>
                    <div className="mt-4 flex flex-col">
                        <span className="text-xs text-left mb-1.5">Do you need help?</span>
                        <a href="tel:00420776116111"
                            className="hover:text-[#3D9123] flex items-center gap-1 text-black text-xs py-0.5">
                            <Phone width={18} height={18} className="opacity-80 p-1" />
                            <span>+420 776 116 111</span>
                        </a>
                        <a href="mailto:info@addai.cz"
                            className="hover:text-[#3D9123] flex items-center gap-1 text-black text-xs py-0.5">
                            <Mail width={18} height={18} className="opacity-80 p-1" />
                            <span>info@addai.cz</span>
                        </a>
                    </div>
                </div>
            )}

            <ProjectsMenu
                open={projectsMenuOpen}
                anchorEl={projectsAnchorEl}
                setOpen={setProjectsMenuOpen}
                setAnchorEl={setProjectsAnchorEl}
                setSelectedProject={setSelectedProject}
                projects={projects}
            />
        </div>
    );
}

export default ProjectSelect;
