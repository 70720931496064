
const Footer = () => {
    return (
        <footer className="flex bg-[#e2e2e2]">
        <span className="block mx-auto py-1.5 opacity-80">Made by AddAI team. Version: 1.1.0</span>
      </footer>
    )
}

export default Footer;
